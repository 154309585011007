import React from 'react'

const Landing = () => (
  <section className="place-content-center flex flex-col w-screen">
    <div className="w-1/2 self-center">
    <h1>Corewar on Fractalcube</h1>
    <p>Corewar is a programming game invented by D. G. Jones and A. K. Dewdney in 1984.</p>
    <ul className="list-disc list-inside">
      <li><a className="underline text-blue-400" target="_new" href="https://corewar.co.uk/dewdney/1984-05.htm">Original article from 1984</a></li>
      <li><a className="underline text-blue-400" target="_new" href="https://en.wikipedia.org/wiki/Core_War">Core War on Wikipedia</a></li>
    </ul>
    <br/>
    <h2>Some helpful guides:</h2>
    <ul className="list-disc list-inside">
      <li><a className="underline text-blue-400" target="_new" href="https://vyznev.net/corewar/guide.html" >The beginners' guide to Redcode (by Ilmari Karonen)</a></li>
      <li><a className="underline text-blue-400" target="_new" href="https://github.com/NoraCodes/corewar32/blob/master/Beginner's%20Guide.pdf">Quickref from Defcon 24 (s/postdecrement/postincrement/)</a></li>
      <li><a className="underline text-blue-400" target="_new" href="https://corewar-docs.readthedocs.io/en/latest/corewar/">Corewar.io Documentation</a></li>
    </ul>
    <br/>
    <h2>Testing your warrior</h2>
    <p>Test your warrior code in the editor window. Note that certain things are not supported: <ul className="list-disc list-inside">
      <li>P-spage</li>
      <li>asserts</li>
      <li>pre-processor constants</li>
      <li>...for more info read <a className="underline text-blue-400" href="https://corewar-docs.readthedocs.io/en/latest/redcode/preprocessor/">corewar.io docs</a></li>
    </ul>
    </p>
    <p><b>Save your work locally!</b> There is no storage in the IDE.</p>
    <br/>
    <h2>Submitting your warrior to the hills</h2>
    <p>The hill management is a free-for-all; please be kind. Did I mention to save your work locally?</p>
    <p>Hints:  <ul className="list-disc list-inside">
      <li>Ensure you have added ALL of the required meta comments.</li>
      <li>Don't forget <b>;redcode-<i>&lt;hillkey&gt;</i></b></li>
      <li>Don't forget <b>;assert 1</b></li>
      <li>Hills are run on the original pMARS 0.9.2 with all ICWS'94 extensions</li>
      <li>You can copy/paste code from the IDE to the hills and v/v</li>
      </ul>
    </p>
    <table className="table-auto divide-y border text-center">
      <thead>
        <tr className="divide-x">
          <th>Hill</th>
          <th>Purpose</th>
        </tr>
      </thead>
      <tbody className="divide-y">
        <tr className="divide-x"><td>garbagepile</td><td>Just throw whatever on here to experiment. Try to credit sources.</td></tr>
        <tr className="divide-x"><td>friends</td><td>Original or derivative works. You should understand every line of what you submit.</td></tr>
      </tbody>
    </table>
    </div>
  </section>
)

export default Landing
